<template>
  <viewcard--c
    :title="project"
    :title2="course"
    :btback="{}"
    :busy="(!project && !course) || isloading"
  >
    <b-modal
      id="modal-cancelar"
      cancel-variant="outline-secondary"
      ok-title="Cancelar"
      cancel-title="Fechar"
      centered
      title="Cancelar Agendamento"
      @ok="onClickCancel(false)"
    >
      <b-form>
        <b-form-group label="Motivo *">
          <v-select
            v-model="reasonSelected"
            :options="reason"
            autocomplete="off"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group label="Descrição (Opcional)">
          <b-form-textarea
            rows="7"
            v-model="reason_desc"
            placeholder="Informe uma breve descrição (opcional)"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      v-model="showCancelChilds"
      id="modal-cancelar-childs"
      cancel-variant="outline-secondary"
      ok-title="Quero cancelar!"
      cancel-title="Fechar"
      centered
      :title="`Cancelamento do Agendamento #${cancelItem.appointment_id}`"
      @ok="onClickCancel(true)"
      v-if="cancelItem"
    >
      <div class="row">
        <div class="col-12">
          <h3>(gravações off)</h3>
          <strong
            >Ao confirmar será cancelado também os agendamentos filhos
            abaixo.</strong
          >
          <ul>
            <li v-for="ch in appointment_childs" :key="ch">{{ ch }}</li>
          </ul>
        </div>
      </div>
    </b-modal>
    <Table
      :fields="fields"
      :list="!isloading && list ? list : []"
      border="full"
      responsive
    >
      <template #name="data">
        <div
          :data-text="data.item.name"
          class="text-truncate ellipsis"
          style="max-width: 400px"
        >
          {{ data.item.name }}
        </div>
      </template>

      <template #order="data" style="width: 10px !important">
        <div class="text-nowrap">
          <div v-if="data.item.denied">
            <feather-icon
              :id="`denied-description-${data.item.id}`"
              size="16"
              icon="XIcon"
            />
            <b-tooltip
              title="Evento cancelado fora do prazo limite, não pode mais ser reagendado."
              :target="`denied-description-${data.item.id}`"
            />
          </div>
          <div v-else>{{ data.item.order }}</div>
        </div>
      </template>
      <template #scheduled="data" style="width: 10px !important">
        <div class="text-nowrap">
          <b-avatar
            :variant="showScheduledVariant(data.item)"
            :id="`scheduled-description-${data.item.id}`"
            size="25"
          >
            <feather-icon
              size="16"
              :icon="showScheduledIcon(data.item)"
            />
          </b-avatar>
          <b-tooltip
            v-if="data.item.scheduled"
            :title="data.item.scheduled_description"
            :target="`scheduled-description-${data.item.id}`"
          />
          <b-tooltip
            v-else-if="data.item.canceled_appointments_id.length > 0"
            :target="`scheduled-description-${data.item.id}`">
            <div>{{ showCanceledAppointmentsTitle(data.item) }}</div>
            <strong v-for="id in data.item.canceled_appointments_id" :key="id" style="cursor: pointer"
            @click="
              $router.push({
                name: 'pedagogic-calendar',
                query: { q: id },
              })
            "
            >#{{ id }}
            </strong>
          </b-tooltip>
          <b-tooltip
            v-else
            title="Não agendado"
            :target="`scheduled-description-${data.item.id}`"
          />
        </div>
      </template>
      <template #sync_calendar="data">
        <div class="text-nowrap">
          <b-avatar
            :variant="data.item.sync_calendar ? 'success' : 'primary'"
            size="25"
          >
            <feather-icon
              size="16"
              :icon="data.item.sync_calendar ? 'CheckIcon' : 'MoreHorizontalIcon'"
              @click="onClickCalendar(data.item)"
            />
          </b-avatar>
        </div>
      </template>
      <template #sync_youtube="data">
        <div class="text-nowrap">
          <b-avatar
            :variant="data.item.sync_youtube ? 'success' : 'primary'"
            size="25"
          >
            <feather-icon
              size="16"
              :icon="data.item.sync_youtube ? 'CheckIcon' : 'MoreHorizontalIcon'"
              @click="onClickYoutube(data.item)"
            />
          </b-avatar>
        </div>
      </template>
      <template #appointment_id="data">
        <div class="text-nowrap">
          <strong
            v-if="data.item.appointment_id"
            @click="
              $router.push({
                name: 'pedagogic-calendar',
                query: { q: data.item.appointment_id },
              })
            "
            >#{{ data.item.appointment_id }}</strong
          >
          <span v-else>--</span>
        </div>
      </template>

      <template #actions="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`edit-lesson-row-${data.item.id}`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            v-b-tooltip.hover.top="'Editar Aula'"
            @click="onClickSave(data.item)"
          />

          <feather-icon
            :id="`rescheduling-lesson-row-${data.item.id}`"
            v-if="data.item.can_rescheduling"
            icon="CalendarIcon"
            size="16"
            class="mx-1"
            v-b-tooltip.hover.top="'Reagendar'"
            @click="onClickRescheduling(data.item)"
          />

          <span
            v-if="data.item.canCancel"
            @click="onClickConfirmCancel(data.item)"
            v-b-modal.modal-cancelar
          >
            Cancelar
          </span>
        </div>
      </template>
    </Table>

    <b-sidebar
      ref="coursesDetailsSideBar"
      id="coursesDetailsSideBar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      v-model="isSave"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }" v-if="isSave">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 v-if="dto.id" class="mb-0">Editar Aula</h5>
          <h5 v-else class="mb-0">Cadastrar Aula</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <project-lesson-form @result="onResult" :dto="dto" />
      </template>
    </b-sidebar>
  </viewcard--c>
</template>

<script>
import { BModal, VBModal, VBTooltip } from "bootstrap-vue";
import { VBToggle } from "bootstrap-vue";
import Table from "@/components/Table.vue";
import _lessonService from "@/services/lessons-service";
import _reasonService from "@/services/reason-service";
import _externalDataBaseService from "@/services/external-database-service";
import project_lesson from "./project-lesson";
import _projectsService from "@/services/projects-service";
import _courseService from "@/services/courses-service";

export default {
  components: {
    "project-lesson-form": project_lesson,
    BModal,
    VBModal,
    Table,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      project: null,
      course: null,
      isloading: false,
      fields: [
        { key: "name", label: "Nome", orderBy: { type: "front" } },
        { key: "order", label: "Aula", orderBy: { type: "front" } },
        { key: "duration", label: "Duração", orderBy: { type: "front" } },
        { key: "appointment_id", label: "Agendamento" },
        { key: "scheduled", label: "Agendado" },
        { key: "sync_calendar", label: "Calendar" },
        { key: "sync_youtube", label: "Youtube" },
        { key: "actions", label: "Ações" },
      ],
      urlImage: `${require("@/assets/images/pages/sem-foto.png")}`,
      list: [],
      isSave: false,
      dto: null,
      cancelItem: null,
      reason: [],
      reasonSelected: null,
      reason_desc: "",

      appointment_childs: [],
      showCancelChilds: false,
    };
  },
  created() {
    this.getRecords();
    this.getReason();
    this.getProject();
    this.getCourse();
  },
  methods: {
    getProject() {
      if (this.$route.params.projectId > 0) {
        _projectsService
          .find(this.$route.params.projectId)
          .then((res) => {
            if (res.content) {
              this.project = res.content.name;
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      }
    },
    getCourse() {
      if (this.$route.params.id > 0) {
        _courseService
          .find(this.$route.params.id)
          .then((res) => {
            if (res.content) {
              this.course = res.content.course.name;
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      }
    },
    getReason() {
      _reasonService
        .show()
        .then((res) => {
          this.reason = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getRecords() {
      this.isloading = true;
      _lessonService
        .show(this.$route.params.id)
        .then((res) => {
          if (res.content) {
            this.list = [];
            this.list.push(...res.content);
          }
          this.isloading = false
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    onClickSave(_record) {
      this.dto = {
        course_id: _record ? _record.course_id : this.$route.params.id,
        id: _record ? _record.id : 0,
      };
      this.isSave = true;
    },
    onClickConfirmCancel(_item) {
      this.cancelItem = _item;
    },
    onClickCancel(childs = false) {
      if (!this.reasonSelected) {
        this.$utils.toastError("OPS!!!", "Selecione o motivo.");
        return;
      }

      const payload = {
        reason_id: this.reasonSelected.value,
        reason_desc: this.reason_desc,
        id: this.cancelItem.id,

        appointment_id: this.cancelItem.appointment_id,
        cancel_childs_confirm: childs,
      };

      this.isloading = true;
      
      _lessonService
        .cancelWithReason(payload)
        .then((response) => {
          if (!response.error) {
            this.$utils.toast("Cancelamento", "Sucesso ao cancelar.");
            this.getRecords();
          } else {
            this.$utils.toastError("Notificação", response.error);
          }
        })
        .catch((error) => {
          if (error && error.split("-")[0] == "cancel_childs_confirm") {
            this.appointment_childs = error.split("-")[1].split(",");
            this.showCancelChilds = true;
          } else {
            this.$utils.toastError("Notificação", error);
          }
        })
        .finally(() => this.onResult());
    },
    onClickRescheduling(_item) {
      this.$router.push({
        path: `/pedagogic/appointment/${_item.appointment_id}`
      });
    },
    onClickJira(_record) {
      this.$utils.toastError("DESCULPE!!!", "função JIRA não disponivel");
    },
    onClickYoutube(_record) {
      this.$utils.toastError("DESCULPE!!!", "função YOUTUBE não disponivel");
    },
    onClickCalendar(_record) {
      if (_record.google_event_url) {
        var a = document.createElement("a");
        a.target = "_blank";
        a.href = `${_record.google_event_url}`;
        a.click();
      }
    },
    onResult(_res) {
      this.isSave = false;
      this.dto = null;
    },
    showScheduledVariant(lesson) {
      if (lesson.scheduled) {
        return 'success';
      }
      else if (lesson.canceled_appointments_id.length > 0) {
        return 'danger';
      }

      return 'primary';
    },
    showScheduledIcon(lesson) {
      if (lesson.scheduled) {
        return 'CheckIcon';
      }
      else if (lesson.canceled_appointments_id.length > 0) {
        return 'XIcon';
      }

      return 'MoreHorizontalIcon';
    },
    showCanceledAppointmentsTitle(lesson) {
      return lesson.canceled_appointments_id.length > 1 ? 'Agendamentos cancelados:' : 'Agendamento cancelado:';
    },
  },
};
</script>
<style>
#table-lessons thead th {
  text-align: center !important;
}

#table-lessons tbody tr {
  text-align: center !important;
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  padding: 0.5rem 0rem;
  font-size: 12px;
}
.ellipsis:focus,
.ellipsis:hover {
  color: transparent;
}
.ellipsis:focus:after,
.ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: nowrap;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
  font-size: 12px;
  z-index: 10;
}
</style>